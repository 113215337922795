import StickyColumn from './modules/_stickycolumn.js';
import Menu from './modules/_menu.js';

class App {
	constructor() {
		this.sc = new StickyColumn();
		this.menu = new Menu();
	}
}

document.addEventListener('DOMContentLoaded', () => {
	let app = new App();
}, false);