import anime from 'animejs/lib/anime.es.js';

export default class StickyColumn{
	constructor(){
		this.initStickyColumn();
		this.initSmoothScroll();
	}

	initStickyColumn(){
		const container = document.querySelector('.main__contents');
		const sticky = document.querySelector('.main__contents__sticky');
		const body = document.querySelector('.main__contents__body');
		let isStick = false;

		function scrollHandler(){
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			const containerRect = container.getBoundingClientRect();
			const stickyRect = sticky.getBoundingClientRect();
			const bodyRect = body.getBoundingClientRect();

			if(containerRect.top < 0){
				isStick = true;
				sticky.style.transform = 'translate(0px,'+Math.max(0, Math.min(-containerRect.top, bodyRect.height-stickyRect.height))+'px)';
			}else{
				if(isStick){
					isStick = false;
					sticky.style.transform = 'translate(0px,0px)';
				}
			}
		}

		if(container && sticky && body){
			window.addEventListener("scroll", scrollHandler, false);
			scrollHandler();
		}
	}

	initSmoothScroll(){
		const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
		const buttons = document.querySelectorAll('.main__contents__sticky a[href^="#"]');
		buttons.forEach((btn)=>{
			const href = btn.getAttribute("href");
			console.log(href);
			if(href.indexOf('#') !== 0) return false;

			btn.addEventListener('click', (e)=>{

				let targetPosition = 0;
				if(href.length > 1){
					const targetDom = document.querySelector(href);
					if(targetDom){
						const rect = targetDom.getBoundingClientRect();
						const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
						targetPosition = rect.top + scrollTop;
					}
				}
				anime({
					targets: scrollElement,
					scrollTop: targetPosition,
					duration: 750,
					easing: 'easeOutExpo'
				});
				e.preventDefault();
				return false;
			}, false);
		});
	}
}