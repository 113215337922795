export default class Menu{
	constructor(){
		const header = document.querySelector('.header');
		const container = document.querySelector('.header__container');
		const toggle = document.querySelector('.header__toggle');
		let isOpen = false;

		function toggleMenu(){
			if(isOpen){
				header.classList.remove('open');
				isOpen = false;
				container.style.width = '0px';
			}else{
				header.classList.add('open');
				isOpen = true;
				resizeHandler();
			}
		}

		toggle.addEventListener('click', (e)=>{
			toggleMenu();
			e.preventDefault();
			return false;
		}, false);

		function resizeHandler(){
			if(!isOpen){
				container.style.width = '0px';
				container.style.height = '0px';
			}else{
				container.style.width = window.innerWidth+'px';
				container.style.height = window.innerHeight+'px';
			}
		}
		window.addEventListener('resize', resizeHandler, false);
		resizeHandler();
	}
}